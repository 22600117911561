<script>
import Icons from '../../mixins/Icons'

export default {
  name: 'IconTwitter',
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len */ -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="w"
    :height="h"
    viewBox="0 0 200 200"
    role="presentation"
  >
    <g id="twitter">
      <path
        :fill="color"
        d="M65.9,174.8c69.4,0,107.4-57.5,107.4-107.4c0-1.6,0-3.3-0.1-4.9c7.4-5.3,13.8-12,18.8-19.5
c-6.8,3-14,5-21.7,5.9c7.8-4.7,13.8-12.1,16.6-20.9c-7.3,4.3-15.4,7.5-24,9.2c-6.9-7.3-16.7-11.9-27.6-11.9
c-20.8,0-37.8,16.9-37.8,37.7c0,3,0.3,5.8,1,8.6C67.2,70,39.4,55,20.8,32.1c-3.2,5.6-5.1,12.1-5.1,19c0,13.1,6.7,24.7,16.8,31.4
c-6.2-0.2-12-1.9-17.1-4.7c0,0.2,0,0.3,0,0.5c0,18.3,13,33.5,30.3,37c-3.2,0.9-6.5,1.3-10,1.3c-2.4,0-4.8-0.2-7.1-0.7
c4.8,15,18.7,25.9,35.3,26.2C51,152.3,34.7,158.3,17,158.3c-3,0-6.1-0.2-9-0.5C24.7,168.5,44.5,174.8,65.9,174.8"
      />
    </g>
  </svg>
</template>
